#preview-window {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
    border-width: 0;
}

#tab-preview {
    border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -ms-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    -ms-border-top-right-radius: 5px;
    -o-border-top-right-radius: 5px;
    border: 0;
    width: 100%;
}

#tab-preview iframe {
    border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -ms-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    -ms-border-top-right-radius: 5px;
    -o-border-top-right-radius: 5px;
    border: 0;
    width: 100%;
    height: 67.5vh;
}

.preview-header {
    background-color: #eeeeee;
    border-bottom: 1px solid #cccccc;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    height: 40px;
}

.preview-header>i.material-icons {
    color: #cccccc;
    cursor: not-allowed;
}

.preview-header .left {
    border: 1px inset #eeeeee;
    cursor: not-allowed;
    height: 25px;
    margin-right: 10px;
    width: 100%;
}

.preview-header .right {
    height: 25px;
    margin-right: 15px;
    width: 20px;
}

.preview-header .left .material-icons {
    font-size: 16px;
    margin-left: 2px;
    margin-right: 5px;
}

.preview-header .right .material-icons {
    cursor: pointer;
}

.preview-footer {
    background-color: #eeeeee;
    border-top: 1px solid #cccccc;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    height: 10px;
}

.jobblock::after {
    content: '';
    clear: both;
    display: block;
    width: 100%;
    height: 1px;
}

#jobs .jobblock a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
