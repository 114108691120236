nav a.brand-logo {
    margin-left: 15px;
}

@media only screen and (max-width: 992px) {
    nav a.brand-logo {
        left: 0;
        margin-left: 15px;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }
}

@media only screen and (max-width:604px) {
    nav a.brand-logo {
        font-size: 20px;
    }
}
